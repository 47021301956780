var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-login",attrs:{"id":"login_page"}},[_c('div',{staticClass:"login-title"},[_c('span',{attrs:{"id":"login_title_text"}},[_vm._v(_vm._s(_vm.$t('common.application.title')))])]),_vm._v(" "),_c('div',{staticClass:"login-title-second"},[_c('span',{attrs:{"id":"login_title_second_text"}},[_vm._v(_vm._s(_vm.$t('common.application.title.second')))])]),_vm._v(" "),_c('div',{staticClass:"login-box"},[_c('div',{staticClass:"content"},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":_vm.$t('username')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'username' ,
							{ rules: [
								{ required: true,whitespace: true, message: _vm.$tt('validate.required','username') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'username' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true,whitespace: true, message: $tt('validate.required','username') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"size":"large","type":"text","placeholder":_vm.$t('username.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('password')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'password' ,
							{ rules: [
								{ required: true, message: _vm.$tt('validate.required','password') },
								{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: _vm.$t('password.err.pattern') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'password' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true, message: $tt('validate.required','password') },\n\t\t\t\t\t\t\t\t{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\\d).{6,40}$/ ,message: $t('password.err.pattern') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"size":"large","type":"password","placeholder":_vm.$t('password.placeholder')}},[_c('ion-icon',{staticClass:"anticon",attrs:{"slot":"prefix","name":"key-outline"},slot:"prefix"})],1)],1),_vm._v(" "),_c('div',{staticClass:"row-forgot"},[_c('my-router-link',{staticClass:"link-forgot",attrs:{"id":"login_forgot_password_link","name":"forgot-password","no-param":""}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('forgot_password'))+"\n\t\t\t\t\t")])],1),_vm._v(" "),_c('a-button',{attrs:{"id":"login_submit_button","type":"primary","loading":_vm.formLoading,"size":"large","html-type":"submit","block":""}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('loginbtn'))+"\n\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"row-or"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('common.or'))+"\n\t\t\t\t")]),_vm._v(" "),_c('a-button',{staticClass:"line-login-btn",attrs:{"id":"login_linelogin_button","loading":_vm.formLoading,"size":"large","block":""},on:{"click":_vm.handleLineLogin}},[_c('img',{staticClass:"logo",attrs:{"src":require("@assets/images/logo_line_login.png")}}),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('line_login.btn'))+"\n\t\t\t\t\t")])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }